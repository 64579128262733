import React, { useState } from 'react'
import Bg from '../../assets/Images/BannerImg.webp';
import { motion } from 'framer-motion';
import { IconMail, IconMapPin, IconPhone } from '@tabler/icons-react';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    companyname: ''
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [apiError, setApiError] = useState('');

  const validate = () => {
    let tempErrors = {};

    if (!formData.firstname) {
      tempErrors.firstname = 'First name is required';
    }

    if (!formData.lastname) {
      tempErrors.lastname = 'Last name is required';
    }

    if (!formData.email) {
      tempErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = 'Email is not valid';
    }

    if (!formData.phone) {
      tempErrors.phone = 'Phone number is required';
    } else if (formData.phone.length < 10) {
      tempErrors.phone = 'Phone number must be at least 10 digits';
    }

    if (!formData.companyname) {
      tempErrors.companyname = 'Company name is required';
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setIsSubmitting(true);
      try {
        const response = await fetch('https://example.com/api/submit', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        });
        
        const data = await response.json();
        if (response.ok) {
          setSuccessMessage('Your form has been submitted successfully!');
          setFormData({ firstname: '', lastname: '', email: '', phone: '', companyname: '' }); // Reset form
          
          // Hide the success message after 3 seconds
          setTimeout(() => {
            setSuccessMessage('');
          }, 3000);
        } else {
          setApiError(data.message || 'Something went wrong');
          
          // Hide the error message after 3 seconds
          setTimeout(() => {
            setApiError('');
          }, 3000);
        }
      } catch (error) {
        setApiError('Network error or server is down');
        
        // Hide the error message after 3 seconds
        setTimeout(() => {
          setApiError('');
        }, 3000);
      }
      setIsSubmitting(false);
    }
  };
  return (
<section 
  className="h-full py-20 flex flex-col items-center justify-center font-RedHat" 
  style={{
    background: `linear-gradient(
        rgba(150, 99, 118, 0.5), /* Semi-transparent gradient color */
        rgba(150, 99, 118, 0.5) /* Semi-transparent gradient color */
      ), url(${Bg})`, /* Background image URL */
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  }}
>
  <div className='w-[90%] h-full flex flex-row  flex-wrap gap-5 lg:gap-0'>
    <div className='text-white flex flex-col gap-5 lg:w-1/2 w-full'>
    <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: false }}
            transition={{ ease: "easeInOut", duration: 0.5 }}
            className="text-4xl font-RedHat font-bold "
          >
            Get in touch
          </motion.h2>
    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus inventore placeat ut quia unde laborum aspernatur reprehenderit asperiores fuga molestiae.</p>
    <ul className='flex flex-col gap-5'>
        <li className='flex flex-col gap-3'>
            
                <h4 className='font-bold text-[1rem]'>Our Location</h4>
                <p className='flex flex-row gap-2 items-center font-semibold'>
                <IconMapPin size={18}/>
                #191, 3rd Floor, Hamid Building, Whites Road, Anna Salai, Chennai – 600 006
                </p>
            
        </li>
        <li className='flex flex-col gap-3'>
            
                <h4 className='font-bold text-[1rem]'>Phone</h4>
                <a href='tel:+919600466139' className='flex flex-row gap-2 items-center font-semibold'>
                <IconPhone size={18}/>
                +91 9600466139
                </a>
            
        </li>
        <li className='flex flex-col gap-3'>
            
                <h4 className='font-bold text-[1rem]'>Email</h4>
                <a href='mailto:reachus@offisbay.com' className='flex flex-row gap-2 items-center font-semibold'>
                <IconMail size={18}/>
                reachus@offisbay.com
                </a>
            
        </li>
    </ul>
    </div>
    <div className='text-white flex flex-col gap-5 lg:w-1/2 w-full'>
    <form className='bg-primary/60 py-10 px-5 rounded-lg w-full flex flex-col gap-5' onSubmit={handleSubmit}>
      <div className='flex lg:flex-row md:flex-row flex-col items-center gap-5 justify-between'>
        <div className='flex flex-col gap-2 lg:w-1/2 md:w-1/2 w-full'>
          <label htmlFor="firstname" className='text-xs'>First Name</label>
          <input 
            type="text" 
            name='firstname' 
            id='firstname' 
            required 
            className={`outline-none border-2 text-black focus:border-primary rounded-lg py-1 ${errors.firstname ? 'border-red-500' : 'border-transparent'}`}
            value={formData.firstname}
            onChange={handleChange}
          />
          {errors.firstname && <p className="text-red-500 text-xs">{errors.firstname}</p>}
        </div>
        <div className='flex flex-col gap-2 lg:w-1/2 md:w-1/2 w-full'>
          <label htmlFor="lastname" className='text-xs'>Last Name</label>
          <input 
            type="text" 
            name='lastname' 
            id='lastname' 
            required 
            className={`outline-none border-2 text-black focus:border-primary rounded-lg py-1 ${errors.lastname ? 'border-red-500' : 'border-transparent'}`}
            value={formData.lastname}
            onChange={handleChange}
          />
          {errors.lastname && <p className="text-red-500 text-xs">{errors.lastname}</p>}
        </div>
      </div>

      <div className='flex flex-col gap-2 w-full'>
        <label htmlFor="email" className='text-xs'>Email</label>
        <input 
          type="email" 
          name='email' 
          id='email' 
          required 
          className={`outline-none border-2 text-black focus:border-primary rounded-lg py-1 ${errors.email ? 'border-red-500' : 'border-transparent'}`}
          value={formData.email}
          onChange={handleChange}
        />
        {errors.email && <p className="text-red-500 text-xs">{errors.email}</p>}
      </div>

      <div className='flex flex-col gap-2 w-full'>
        <label htmlFor="phone" className='text-xs'>Phone</label>
        <input 
          type="number" 
          name='phone' 
          id='phone' 
          required 
          className={`outline-none border-2 text-black focus:border-primary rounded-lg py-1 ${errors.phone ? 'border-red-500' : 'border-transparent'}`}
          value={formData.phone}
          onChange={handleChange}
        />
        {errors.phone && <p className="text-red-500 text-xs">{errors.phone}</p>}
      </div>

      <div className='flex flex-col gap-2 w-full'>
        <label htmlFor="companyname" className='text-xs'>Company Name</label>
        <input 
          type="text" 
          name='companyname' 
          id='companyname' 
          required 
          className={`outline-none border-2 text-black focus:border-primary rounded-lg py-1 ${errors.companyname ? 'border-red-500' : 'border-transparent'}`}
          value={formData.companyname}
          onChange={handleChange}
        />
        {errors.companyname && <p className="text-red-500 text-xs">{errors.companyname}</p>}
      </div>

      <motion.button
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: false }}
        transition={{ ease: "easeInOut", duration: 0.5 }}
        className="bg-primary py-2 px-8 text-[0.8rem] rounded-md font-Montserrat relative group"
        type="submit"
        disabled={isSubmitting}
      >
        <div className="absolute inset-0 bg-white/10 w-0 group-hover:w-full transition-all ease-in-out duration-300 rounded-md"></div>
        {isSubmitting ? 'Submitting...' : 'Submit'}
      </motion.button>

      {successMessage && <p className="text-green-500 text-sm mt-2">{successMessage}</p>}
      {apiError && <p className="text-red-500 text-sm mt-2">{apiError}</p>}
    </form>
    </div>

  </div>
</section>
  )
}

export default ContactUs