import React from 'react'
import Header from './Components/Header'
import { Route, Routes, useLocation } from 'react-router-dom'
import ScrollToTop from './ScrollToTop'
import Home from './Screens/Home'
import Footer from './Components/Footer'
import OfficeSpaces from './Screens/OffisSpaces'
import ContactUs from './Screens/Contactus'
import PageNotFound from './Screens/404/404'
import BookingScreen from './Screens/Booking'
import BacktoTopButton from './Components/BackToTop'
import PrivacyPolicy from './Screens/PrivacyPolicy/Index'
import TandC from './Screens/TermsandConditions'
import FloatingWhatsapp from './Components/FloatingWhtsapp'

const App = () => {
  const location = useLocation()
  return (
    <>
    <Header/>
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/offisspaces/' element={<OfficeSpaces/>}/>
      <Route path='/offisspaces/:spacename' element={<OfficeSpaces/>}/>
      {/* <Route path='/offiss' element={<BookingScreen/>}/> */}
      <Route path='/privacypolicy' element={<PrivacyPolicy/>}/>
      <Route path='/termsandconditions' element={<TandC/>}/>
      <Route path='/contact' element={<ContactUs/>}/>
      <Route path='*' element={<PageNotFound/>}/>
    </Routes>
    <Footer/>
    <ScrollToTop/>
    <FloatingWhatsapp/>
    <BacktoTopButton/>
    </>

  )
}

export default App
