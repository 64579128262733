import {
  IconCaretDown,
  IconCaretUp,
  IconDeviceDesktopExclamation,
  IconDevicesExclamation,
  IconStar,
} from "@tabler/icons-react";
import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { Link, useLocation } from "react-router-dom";
import m1 from "../../assets/Images/m1.webp";
import m2 from "../../assets/Images/m2.webp";
import m3 from "../../assets/Images/m3.webp";
import m4 from "../../assets/Images/m4.webp";
import m5 from "../../assets/Images/m5.webp";
import m6 from "../../assets/Images/m6.webp";

const OfficeSpaces = () => {
  const location = useLocation();


  

  const coworkingSpaces = [
    {
      id: 1,
      category: [
        {
          name: "Hot Desk (Hourly)",
          bannerImg: m2,
          price: "₹150/Hour",
          availableTime: "08:00 AM - 08:00 PM",
          availableDays: ["S", "M", "T", "W", "T", "F", "S"],
          amenities: ["Wifi", "Tea Coffee", "Printer", "Parking"]
        },
        {
          name: "Hot Desk (Daily)",
          bannerImg: m2,
          price: "₹800/Day",
          availableTime: "08:00 AM - 08:00 PM",
          availableDays: ["S", "M", "T", "W", "T", "F", "S"],
          amenities: ["Wifi", "Tea Coffee", "Printer", "Parking"]
        }
      ],
      bannerImg: m2,
      name: "Hot Desk",
    },
    {
      id: 2,
      category: [
        {
          name: "2-Seater Cabins",
          bannerImg: m4,
          seats: 2,
          price: "₹12,000/Month",
          cabins: ["Cabin 6", "Cabin 9"],
          availableTime: "08:00 AM - 08:00 PM",
          availableDays: ["S", "M", "T", "W", "T", "F", "S"],
          amenities: ["Wifi", "Tea Coffee", "Printer", "Parking", "Reception"]
        },
        {
          name: "4-Seater Cabins",
          bannerImg: m4,
          seats: 4,
          price: "₹24,000/Month",
          cabins: ["Cabin 7", "Cabin 8", "Cabin 11"],
          availableTime: "08:00 AM - 08:00 PM",
          availableDays: ["S", "M", "T", "W", "T", "F", "S"],
          amenities: ["Wifi", "Tea Coffee", "Printer", "Parking", "Reception"]
        },
        {
          name: "8-Seater Cabin",
          bannerImg: m4,
          seats: 8,
          price: "₹48,000/Month",
          cabins: ["Cabin 3"],
          availableTime: "08:00 AM - 08:00 PM",
          availableDays: ["S", "M", "T", "W", "T", "F", "S"],
          amenities: ["Wifi", "Tea Coffee", "Printer", "Parking", "Reception"]
        },
        {
          name: "9-Seater Cabins",
          bannerImg: m4,
          seats: 9,
          price: "₹54,000/Month",
          cabins: ["Cabin 4", "Cabin 10"],
          availableTime: "08:00 AM - 08:00 PM",
          availableDays: ["S", "M", "T", "W", "T", "F", "S"],
          amenities: ["Wifi", "Tea Coffee", "Printer", "Parking", "Reception"]
        },
        {
          name: "10-Seater Cabins",
          bannerImg: m4,
          seats: 10,
          price: "₹60,000/Month",
          cabins: ["Cabin 2", "Cabin 5"],
          availableTime: "08:00 AM - 08:00 PM",
          availableDays: ["S", "M", "T", "W", "T", "F", "S"],
          amenities: ["Wifi", "Tea Coffee", "Printer", "Parking", "Reception"]
        },
        {
          name: "20-Seater Cabin",
          bannerImg: m4,
          seats: 20,
          price: "₹1,20,000/Month",
          cabins: ["Cabin 1"],
          availableTime: "08:00 AM - 08:00 PM",
          availableDays: ["S", "M", "T", "W", "T", "F", "S"],
          amenities: ["Wifi", "Tea Coffee", "Printer", "Parking", "Reception"]
        }
      ],
      bannerImg: m4,
      name: "Cabins",
    },
    {
      id: 3,
      category: [
        {
          name: "Virtual Office (Monthly)",
          bannerImg: m3,
          price: "₹3,000/Month + GST",
          availableTime: "08:00 AM - 08:00 PM",
          availableDays: ["S", "M", "T", "W", "T", "F", "S"],
          amenities: ["Wifi", "Tea Coffee", "Printer", "Mentor", "Reception"]
        },
        {
          name: "Virtual Office (Annually)",
          bannerImg: m3,
          price: "₹18,000/Annually + GST",
          availableTime: "08:00 AM - 08:00 PM",
          availableDays: ["S", "M", "T", "W", "T", "F", "S"],
          amenities: ["Wifi", "Tea Coffee", "Printer", "Parking", "Reception"]
        }
      ],
      bannerImg: m3,
      name: "Virtual Office",
    },
    {
      id: 4,
      category: [
        {
          name: "Small Meeting Room",
          bannerImg: m1,
          seats: 5,
          price: "₹600/Hour",
          availableTime: "08:00 AM - 08:00 PM",
          availableDays: ["S", "M", "T", "W", "T", "F", "S"],
          amenities: ["Wifi", "Tea Coffee", "Printer", "Parking", "Meeting"]
        },
        {
          name: "Large Meeting Room",
          bannerImg: m1,
          seats: 15,
          price: "₹1,000/Hour",
          availableTime: "08:00 AM - 08:00 PM",
          availableDays: ["S", "M", "T", "W", "T", "F", "S"],
          amenities: ["Wifi", "Tea Coffee", "Printer", "Parking", "Meeting"]
        }
      ],
      bannerImg: m1,
      name: "Meeting Room",
    }
  ];
  
  
  const amenitiesList = [
    "Conference",
    "Courier",
    "Elevator",
    "Food",
    "Handicap",
    "IVR",
    "Lounge",
    "LCD",
    "Meeting",
    "Mentor",
    "Mic",
    "Parking",
    "Printer",
    "Projector",
    "Reception",
    "Storage",
    "Tea Coffee",
    "Wifi",
    "Collab Zone",
  ];


  const [showTypeFilter, setShowTypeFilter] = useState(false);
  const [showamenities, setShowAmenties] = useState(false);
  const [selectedSpace, setselectedSpace] = useState([]);
  const [selectedAmenities, setSelectedAmenities] = useState([]);

  const handleCategoryChange = (category) => {
    setselectedSpace((prev) =>
      prev.includes(category)
        ? prev.filter((c) => c !== category)
        : [...prev, category]
    );
  };

  const handleAmenitiesChange = (amenity) => {
    setSelectedAmenities((prev) =>
      prev.includes(amenity)
        ? prev.filter((a) => a !== amenity)
        : [...prev, amenity]
    );
  };

  

  useEffect(()=>{
    if(location.state && location.state.spaceId){
      const parseSpaceID = parseInt(location.state.spaceId,10)
      const data = coworkingSpaces.find(space=>space?.id === parseSpaceID);
      setselectedSpace(data ? [data.name]:[])
    }
  },[location.state])

  const filteredCoworkingSpaces = coworkingSpaces.filter((space) => {
    const categoryMatch =
      selectedSpace.length === 0 ||
      selectedSpace.includes(space.name);
    const amenitiesMatch =
      selectedAmenities.length === 0 ||
      selectedAmenities.every((amenity) => space.category.find(obj=>obj.amenities.includes(amenity)));

    return categoryMatch && amenitiesMatch;
  });

  const filterRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setShowTypeFilter(false);
        setShowAmenties(false);
      }
    };

    if (showTypeFilter || showamenities) {
      return document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showTypeFilter, showamenities, filterRef]);


  
  return (
    <>
      <section className="min-h-[10vh] py-3 border-y-2  flex flex-col items-center justify-center font-RedHat sticky  !z-10 top-0 w-full bg-white  " style={{zIndex:0}}>
        <div className="w-[90%] flex flex-row justify-between items-center flex-wrap gap-5 !z-0 bg-white">
          <div className="flex flex-col gap-2">
            <ul className="flex flex-row gap-2 font-medium text-gray-400 ">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <span> {">"} </span>
              </li>
              <li>
                <a href="/office Spaces">Office Spaces</a>
              </li>
            </ul>
            <h2 className="text-[1.3rem] font-semibold">
              Office Spaces on Rent in Chennai Coworking
            </h2>
          </div>
          <div className="flex flex-row items-center gap-5 relative  justify-center">
            <h4 className="font-bold uppercase text-primary text-[1rem]">
              Filter By
            </h4>
            <button
              className="flex flex-row items-center gap-2 font-semibold"
              onClick={() => setShowTypeFilter(!showTypeFilter)}
            >
              <span>Type</span>
              {showTypeFilter ? (
                <IconCaretUp className="fill-primary stroke-primary" />
              ) : (
                <IconCaretDown className="fill-primary stroke-primary" />
              )}
            </button>
            {showTypeFilter && (
              <motion.div
                ref={filterRef}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: false }}
                transition={{ ease: "easeInOut", duration: 0.3 }}
                className="absolute bg-white top-16 right-16 py-5 flex flex-col items-start gap-5 px-5"
              >
                {coworkingSpaces.map((items) => (
                  <div className="flex flex-row items-center gap-2">
                    <input
                      type="checkbox"
                      className=" checked:bg-primary peer-checked:bg-primary accent-primary rounded-full"
                      name={items.name}
                      id={items.name}
                      onChange={() => {
                        // setTimeout(() => {
                        //   setShowTypeFilter(!showTypeFilter);
                        // }, 2000);
                        handleCategoryChange(items.name);
                      }}
                      checked={selectedSpace.includes(items.name)}
                    />
                    <p>{items.name}</p>
                  </div>
                ))}
              </motion.div>
            )}

            <button
              className="flex flex-row items-center gap-2 font-semibold"
              onClick={() => setShowAmenties(!showamenities)}
            >
              More
              {showamenities ? (
                <IconCaretUp className="fill-primary stroke-primary" />
              ) : (
                <IconCaretDown className="fill-primary stroke-primary" />
              )}
            </button>
            {showamenities && (
              <motion.div
                ref={filterRef}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: false }}
                transition={{ ease: "easeInOut", duration: 0.3 }}
                style={{ zIndex: 10 }}
                className="absolute bg-white top-16 lg:right-16  py-5 grid lg:grid-cols-3 grid-cols-2 w-[150%] ml-[50%]  lg:w-[500px] items-start gap-5 px-5"
              >
                {amenitiesList.map((items) => (
                  <div className="flex flex-row items-center gap-2 flex-wrap">
                    <input
                      type="checkbox"
                      className=" checked:bg-primary peer-checked:bg-primary accent-primary rounded-full"
                      name={items}
                      id={items}
                      onChange={() => {
                        handleAmenitiesChange(items);
                      }}
                      checked={selectedAmenities.includes(items)}
                    />
                    <p>{items}</p>
                  </div>
                ))}
              </motion.div>
            )}
          </div>
        </div>
      </section>
      <section className="h-full py-10 flex flex-col items-center  font-RedHat">
        {filteredCoworkingSpaces.length === 0 ? (
          <div className=" min-h-[70vh] w-[90%] flex flex-col items-center justify-center gap-5 ">
            <IconDeviceDesktopExclamation
              size={100}
              className="stroke-gray-500"
            />
            <h2 className="text-gray-500 font-bold  capitalize text-[1.8rem]">
              No work space found
            </h2>
          </div>
        ) : (
          <div className="w-[90%]  h-full grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4">
            {filteredCoworkingSpaces.map((items) => (
          <>
          {
            items.category.map((obj,index)=>(
              <div className="bg-white flex flex-col gap-5 border-2 rounded-lg shadow-md   ">
              <div
                className="min-h-[40vh] flex flex-col justify-end"
                style={{
                  backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${obj.bannerImg})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="text-white px-3 py-2 font-bold flex flex-row justify-between items-center">
                  <h5>{obj.name}</h5>
                  <span className="flex flex-row items-center gap-2 bg-primary px-2 rounded-lg ">
                    <IconStar className="stroke-white fill-white" size={15} />
                    5
                  </span>
                </div>
              </div>
              <div className="flex flex-row justify-between px-3">
                <div>
                  <h6 className="text-[0.8rem] font-semibold text-primary">
                    Available Time
                  </h6>
                  <p className="text-[0.9rem] font-medium">
                    {obj.availableTime}
                  </p>
                </div>
                <div>
                  <h6 className="text-[0.8rem] font-semibold text-primary">
                    Available Days
                  </h6>
                  <p className="flex flex-row gap-1 text-[0.9rem] font-medium">
                    {obj.availableDays.map((day, index) => (
                      <span
                        key={index}
                        className={`${
                          index === 0 ? "text-red-500" : " text-gray-700" // Add color for the first item
                        } `}
                      >
                        {day}
                      </span>
                    ))}
                  </p>
                </div>
              </div>
              <div>
                <h4 className="text-[1.2rem] font-bold text-primary text-center">
                  Rs.{obj.price}
                </h4>
              </div>
              <div className="flex flex-row justify-between px-3 py-4 ">
                <Link to={`/contact`} state={{ cabinId: `${items.id}` }}>
                  <motion.button
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: false }}
                    transition={{ ease: "easeInOut", duration: 0.5 }}
                    style={{ zIndex: 0 }}
                    className={`bg-primary !z-0  py-2 px-8 text-[0.8rem] text-white rounded-md font-Montserrat relative group`}
                  >
                    <div className="absolute  inset-0 bg-white/10 w-0 group-hover:w-full transition-all ease-in-out duration-300 rounded-md"></div>
                    Request a visit
                  </motion.button>
                </Link>

                <a href="https://cwms-dev.ideassionlive.in/booking">
                  <motion.button
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: false }}
                    transition={{ ease: "easeInOut", duration: 0.5 }}
                    style={{ zIndex: 0 }}
                    className={`bg-primary !z-0  py-2 px-8 text-[0.8rem] text-white rounded-md font-Montserrat relative group`}
                  >
                    <div className="absolute  inset-0 bg-white/10 w-0 group-hover:w-full transition-all ease-in-out duration-300 rounded-md"></div>
                    Book Now
                  </motion.button>
                </a>
              </div>
            </div>
            ))
          }
          </>
            ))}
          </div>
        )}
      </section>
    </>
  );
};

export default OfficeSpaces;
