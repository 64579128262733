import { IconExclamationCircle } from '@tabler/icons-react'
import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

const PageNotFound = () => {
  return (
    
    <section className='min-h-screen  font-RedHat flex flex-col items-center justify-center'>
        <div className='flex flex-col items-center gap-3'>
            <h2 className='flex flex-row items-center gap-1 text-8xl font-bold text-center'>4<IconExclamationCircle className='fill-primary stroke-white text-8xl' size={96}/>4</h2>
            <p className='text-center text-gray-600 text-xl'>The Page you are looking for was not found</p>
            <Link to={'/'}>            <motion.button
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: false }}
              transition={{ ease: "easeInOut", duration: 0.5 }}
              className="bg-primary py-2 px-8 text-[0.8rem] rounded-md font-Montserrat relative group text-white"
            >
              <div className="absolute inset-0 bg-white/10 w-0 group-hover:w-full transition-all ease-in-out duration-300 rounded-md"></div>
              Back to home
            </motion.button></Link>
        </div>

    </section>
  )
}

export default PageNotFound