import React from "react";
import Logo from "../../assets/Images/logoblack.webp";
import { Link } from "react-router-dom";
import IcoLogo from '../../assets/Images/fav.png'
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTwitter,
  IconBrandX,
  IconMailFilled,
  IconMapPin,
  IconMapPin2,
  IconMapPinFilled,
  IconPhoneFilled,
  IconX,
} from "@tabler/icons-react";

const Footer = () => {
  const quickLinks = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Office Space",
      path: "/offisspaces",
    },
    {
      name: "Contact us",
      path: "/contact",
    },
  ];
  const supportLinks = [
    {
      name: "Privacy Policy",
      path: "/privacypolicy",
    },
    {
      name: "Terms and Conditions",
      path: "/termsandconditions",
    },
  ];

  return (
    <footer className="pt-5 border-t-4 flex flex-col items-center justify-center font-Poppins ">
      <div className="w-[90%] h-full grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-0 ">
        <div className="flex flex-col items-start  space-y-5">
          <div className="h-[35px]">
            <img
              src={Logo}
              alt="logo.png"
              className="h-full w-full object-contain"
            />
          </div>
          <div>
            <h3 className="font-medium text-xl text-left my-1">
              Your Perfect Workspace
            </h3>
            <p className="text-xs text-gray-500 text-left my-1">
              Coworking space in Chennai
            </p>
          </div>
          <ul className="flex flex-col gap-2">
            <li className="flex flex-row gap-2 text-[0.8rem] items-start">
              <IconMapPinFilled
                size={25}
                className=" stroke-primary fill-primary"
              />
              <span>
                #191, 3rd Floor, Hamid Building, Whites Road, Anna Salai,
                Chennai – 600006.
              </span>
            </li>
            <li className="flex flex-row gap-2 text-[0.8rem] items-center">
              <IconPhoneFilled
                size={23}
                className=" stroke-white fill-primary"
              />
              <a href="tel:+919600466139">+91 9600466139</a>
            </li>
            <li className="flex flex-row gap-2 text-[0.8rem] items-center">
              <IconMailFilled
                size={23}
                className=" stroke-white fill-primary"
              />
              <a href="mailto:reachus@offisbay.com">reachus@offisbay.com</a>
            </li>
          </ul>
        </div>

        <div className="flex flex-col items-start space-y-5 px-5 py-5 ">
          <h4 className="text-xl font-medium">Quick Links</h4>
          <ul className="flex flex-col gap-5">
            {quickLinks.map((items, index) => (
              <li
                key={index}
                className=" items-center text-[0.9rem] font-medium group"
              >
                <a href={items.path} className="hover:text-primary">
                  {items.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex flex-col items-start space-y-5 px-5 py-5 ">
          <h4 className="text-xl font-medium">Support</h4>
          <ul className="flex flex-col gap-5">
            {supportLinks.map((items, index) => (
              <li
                key={index}
                className=" items-center text-[0.9rem] font-medium group"
              >
                <a href={items.path} className="hover:text-primary">
                  {items.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex flex-col items-start space-y-5 px-5 py-5 ">
          <h4 className="text-xl font-medium">Social Links</h4>
          <ul className="flex flex-row gap-5">
            <li className=" items-center text-[0.9rem] font-medium group">
              <a href="https://www.facebook.com/people/Offis-Bay/61553566943470/?mibextid=ZbWKwL" target='_blank' className="">
                <IconBrandFacebook size={30} className="stroke-white bg-primary p-1 rounded-full hover:bg-primary/70" />
              </a>
            </li>
            <li className=" items-center text-[0.9rem] font-medium group">
              <a href="https://www.instagram.com/offisbay/" target="_blank" className="">
                <IconBrandInstagram size={30} className="stroke-white bg-primary p-1 rounded-full hover:bg-primary/70" />
              </a>
            </li>
            <li className=" items-center text-[0.9rem] font-medium group">
              <a href="https://www.linkedin.com/company/offis-bay/" target="_blank" className="">
                <IconBrandLinkedin size={30} className="stroke-white bg-primary p-1 rounded-full hover:bg-primary/70" />
              </a>
            </li>
            <li className=" items-center text-[0.9rem] font-medium group">
              <a href="https://x.com/OffisBay" target="_blank" className="">
                <IconBrandX size={30} className="stroke-white bg-primary p-1 rounded-full hover:bg-primary/70" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="border-t-2 w-full flex flex-row items-center gap-2 justify-center py-5">
        <p className="text-[0.8rem] text-center  font-medium">Copyright © {new Date().getFullYear()} <span className="text-primary">Offisbay </span> All rights reserved. </p><img src={IcoLogo} className="h-4 w-4 object-contain"/>
      </div>
    </footer>
  );
};

export default Footer;
