import React from 'react'

const PrivacyPolicy = () => {
  return (
    <>
    <section className='bg-primary/70 flex flex-col items-center justify-center min-h-[30vh] font-RedHat'>
    <h3 className='text-[2.5rem] font-bold text-white'>Privacy Policy</h3>
    </section>
    <section className='min-h-[70vh] flex flex-col items-center justify-center font-Montserrat '>
        <div className='w-[80%] flex flex-col items-center space-y-5'> 

            <h2 className='text-[1rem] font-medium text-text text-center'>At Offis Bay Realty Private Limited, we value your privacy and are committed to maintaining the highest level of protection for your personal data</h2>

            <ul className='flex flex-col items-start justify-center gap-5 list-disc text-text'>
                <li className=''><strong>Data Collection:</strong> We collect personal data that you voluntarily provide to us when expressing interest in our services or making inquiries. This data includes your name, email address, phone number, and other information necessary to provide our services. </li>
                <li className=''>
    <strong>Use of Data:</strong> We use your personal data to respond to your inquiries, provide our services, manage our contractual relationships, and send you informational and promotional content.
  </li>
  <li className=''>
    <strong>Data Sharing:</strong> We do not sell or share your personal data with third parties except as necessary to provide our services, comply with the law, or protect our rights.
  </li>
  <li className=''>
    <strong>Data Security:</strong> We implement a variety of security measures to protect your personal data. However, no method of transmission over the internet or method of electronic storage is 100% secure.
  </li>
  <li className=''>
    <strong>Data Collection:</strong> We collect personal data that you voluntarily provide to us when expressing interest in our services or making inquiries. This data includes your name, email address, phone number, and other information necessary to provide our services.
  </li>
            </ul>

        </div>

    </section>
    </>
  )
}

export default PrivacyPolicy