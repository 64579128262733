import React from 'react'

const TandC = () => {
  return (
    <>
    <section className='bg-primary/70 flex flex-col items-center justify-center min-h-[30vh] font-RedHat'>
    <h3 className='text-[2.5rem] font-bold text-white'>Terms and Conditions</h3>
    </section>
    <section className='min-h-[70vh] flex flex-col items-center justify-center font-Montserrat '>
        <div className='w-[80%] flex flex-col items-center space-y-5'> 

            <h2 className='text-[1rem] font-medium text-text text-center'>Welcome to the Offis Bay Realty Private Limited’s website. Please read these terms and conditions carefully before using our website.</h2>

            <ul className='flex flex-col items-start justify-center gap-5 list-disc text-text'>
            <li className=''>
    <strong>Acceptance of Terms:</strong> By accessing and using this website, you accept and agree to be bound by the terms and provision of this agreement.
  </li>
  <li className=''>
    <strong>Service Provision:</strong> We reserve the right to refuse service to anyone for any reason at any time.
  </li>
  <li className=''>
    <strong>Content Ownership:</strong> All content on this site, including text, graphics, logos, images, and digital downloads, is the property of Offis Bay and is protected by copyright laws.
  </li>
  <li className=''>
    <strong>Limitation of Liability:</strong> Offis Bay is not liable for any indirect, special, incidental, or consequential damages related to the use of this site.
  </li>
  <li className=''>
    <strong>Pricing & Checkout:</strong> Prices are dependent on the varying market conditions and are not fixed. Please contact <a href="mailto:reachus@offisbay.com" className='text-primary font-medium hover:text-red-600'>reachus@offisbay.com</a> for detailed pricing and checkout.
  </li>
            </ul>

        </div>

    </section>
    </>
  )
}

export default TandC